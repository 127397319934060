<template>
  <a-card v-if="hasClient" :bordered="false" style="margin: 24px">
    <div style="display: flex;align-items: center;">
      当前登录账号：
      <span style="heigh: 32px" title="淘宝" class="tb_icon" />
      <a-button
        type="link"
        style="padding: 0"
        @click="loginClient('TAOBAO', true)"
        v-text="
          user.taobao.name && user.taobao.id ? user.taobao.name : '请登录'
        "
      />
      <span style="margin-left: 8px;">发货定时(秒)：</span>
      <a-input-number
        :max="6000"
        :min="180"
        :precision="0"
        v-model="timing.time"
        style="width: 120px"
        @pressEnter="changeTime"
      />
      <a-button type="primary" @click="control" style="margin-left: 10px;">
        <span v-if="timing.flag">关闭定时发货</span>
        <span v-else>开启定时发货</span>
      </a-button>
      <a-button style="margin-left: 8px;" @click="deleteLog">
        清空发货日志
      </a-button>
      <a-button style="margin-left: 8px;" @click="deleteErrLog">
        清空错误日志
      </a-button>
    </div>
    <div style="margin-top: 12px;">
      <a-row :gutter="24">
        <a-col :span="12">
          <p style="font-weight: bold; margin-bottom: 6px;">发货日志：</p>
          <a-textarea v-model="log" placeholder="发货日志" rows="7" />
        </a-col>
        <a-col :span="12">
          <p style="font-weight: bold; margin-bottom: 6px;">错误日志：</p>
          <a-textarea v-model="errorLog" placeholder="错误日志" rows="7" />
        </a-col>
      </a-row>
    </div>
  </a-card>
  <a-card v-else style="margin: 24px">
    <a-empty description="请用客户端打开页面" />
  </a-card>
</template>

<script>
import { sendMessage, sendMessageSync } from '@/client/message'
import { requestClientBase } from '@/client/requestClientBase'
import { CLIENT_LOGIN } from '@/store/mutation-types'
import { setDocumentTitle } from '@/utils/domUtil' // TODO dyClient 利用本地客户端请求
import Vue from 'vue'
import {
  getListParams,
  queryLog,
  createErrLogSrt,
  createLogStr,
  clearLog,
  clearErrLog
} from './utils'
import dayjs from 'dayjs'
import idb from './db'

const getTBName = cookieStr => {
  const nicknameList = ['sn', 'lid', 'dnk', 'tracknick', '_nk_']
  const nameList = cookieStr
    .split(';')
    .map(item => {
      const [key, value] = item.split('=')
      // eslint-disable-next-line
      if (nicknameList.includes(key)) { return eval("'" + decodeURIComponent(decodeURI(value)) + "'") }
    })
    .filter(item => item !== undefined)
  return { name: nameList[0], tbId: cookieStr.split('unb=')[1].split(';')[0] }
}

export default {
  data () {
    return {
      hasClient: false,
      user: {
        taobao: { login: false, name: '', id: '', cookie: '', orderTotal: 0 }
      },
      clientOrderList: [],
      timing: { time: 180, flag: false }, // 每60s刷新一次
      log: '',
      errorLog: '',
      logList: [],
      errorLogList: [],
      errReason: {}
    }
  },
  async mounted () {
    if (!window.client) {
      return this.$message.error('请使用客户端打开页面')
    }
    this.hasClient = true
    const userInfo = Vue.ls.get('CLIENT_LOGIN')
    this.user.taobao = userInfo.taobao

    const { log, errLog } = await queryLog()
    this.logList = log
    this.errorLogList = errLog
    this.log = createLogStr(log)
    this.errorLog = createErrLogSrt(errLog)
    this.control()

    // 更新 cookie
    window.electron.ipcRenderer.on('x5sec', (_, args) => {
      const cookie = this.user.taobao.cookie
      this.user.taobao.cookie = `${cookie}x5sec=${args}`
      Vue.ls.set(CLIENT_LOGIN, this.user, 7 * 24 * 60 * 60 * 1000)
    })
  },
  methods: {
    control () {
      const { timing } = this
      const { flag, time } = timing
      clearInterval(this.interval)
      if (flag) {
        clearInterval(this.interval)
        this.$message.success('关闭定时发货成功')
      } else {
        // this.send();
        this.interval = setInterval(this.send, (Number(time) || 180) * 1000)
        this.$message.success('开启定时发货成功')
      }
      timing.flag = !timing.flag
    },
    async send () {
      const cookieStr = this.user.taobao.cookie
      if (cookieStr === '') {
        this.user.taobao.login = false
        this.user.taobao.name = '淘宝登录过期或者未登录'
        this.$message.error('淘宝登录过期或者未登录，请重试')
        return
      }
      await this.loginClient('TAOBAO', false)
      const orderList = JSON.parse(JSON.stringify(this.clientOrderList))
      // 发货
      const shipResult = await sendMessage(
        { orderList, cookie: cookieStr },
        'ship-order'
      )

      const { response } = shipResult
      const insertParams = {
        url: response.url ? response.url : null,
        create_time: dayjs().valueOf(),
        message: response.msg
      }
      if (response.error) {
        await idb.error_log.add(insertParams)
        this.errorLogList.unshift(insertParams)
        this.errorLog = createErrLogSrt(this.errorLogList)
      } else {
        await idb.log.add(insertParams)
        this.logList.unshift(insertParams)
        this.log = createLogStr(this.logList)
      }
    },
    // 登录客户端的店铺
    async loginClient (type, login) {
      // 登录淘宝，打开客户端页面，获取cookie
      if (type === 'TAOBAO') {
        const cookieInfo = await new Promise(resolve => {
          try {
            if (login) {
              const res = sendMessageSync(
                {
                  openUrl:
                    'https://trade.taobao.com/trade/itemlist/list_sold_items.htm',
                  closeReferrer: 'itemlist/SoldQueryAction',
                  closeFilter: { urls: ['*://*.taobao.com/*'] },
                  closeUrl: 'itemlist/asyncSold.htm',
                  executeJavaScript:
                    "try{document.getElementsByClassName('switch-btn')[0].click();} catch(e){} try{Array.from(document.getElementsByClassName('tabs-mod__tab___1e6Y8')).forEach(it => {if (it.getAttribute('data-reactid') && it.getAttribute('data-reactid').indexOf('waitSend') > -1  && it.className.indexOf('selected') < 0 ){it.click()}});} catch(e){if(location.href.indexOf('itemlist/list_sold_items.htm') > -1){document.getElementsByClassName('button-mod__primary___TqWy8')[0].click()}}try{}catch(e){} " // 异常处理，先不管这个
                },
                'cookieGet'
              )
              resolve(res.cookies)
            } else {
              resolve(this.user.taobao.cookie)
            }
          } catch (error) {
            console.error('登录失败', error)
          }
        })

        if (cookieInfo) {
          try {
            const { name, tbId } = getTBName(cookieInfo)
            const tempObj = { login: true, name, id: tbId, cookie: cookieInfo }
            this.user.taobao = {
              ...this.user.taobao,
              ...tempObj
            }
            // 持久化
            this.saveClient()
            const { url, headers, dataParam } = getListParams(cookieInfo)
            // 获取本地订单
            let pageNum = 1
            do {
              dataParam.pageNum = pageNum
              const orderList = await requestClientBase({
                url,
                method: 'POST',
                headers,
                data: new URLSearchParams(dataParam).toString()
              })

              if (orderList.err && orderList.msg.msg === '淘宝登录过期') {
                this.user.taobao.login = false
                this.user.taobao.name = '淘宝登录过期, 点击重新登录'
                this.$message.error('淘宝登录过期')
                setDocumentTitle(`礼品代发`)
                this.user.taobao = { login: false, name: '', id: '', cookie: '', orderTotal: 0 }
                Vue.ls.set(CLIENT_LOGIN, this.user, 7 * 24 * 60 * 60 * 1000)
                pageNum = 0 // 跳出循环
                return
              }

              this.user.taobao.orderTotal = orderList.page.totalNumber

              const resultList = []
              const { mainOrders } = orderList

              for (let index = 0; index < mainOrders.length; index++) {
                const { id } = orderList.mainOrders[index]
                try {
                  const addrInfo = await sendMessage(
                    { cookie: headers.cookie, orderId: id },
                    'get-mobile-address'
                  )
                  const { buyer, mobile, address } = addrInfo.result
                  resultList.push({
                    orderNo: id,
                    name: buyer,
                    mobile,
                    addressStr: address
                  })
                } catch (error) {
                  console.log('[error] ------> ', error)
                  this.errorLog += error + '\n'
                }
              }
              this.clientOrderList = resultList
              pageNum++
              // 本次订单没有查到15个跳出
              if (orderList.mainOrders.length < 15) pageNum = 0
            } while (pageNum > 0)
            console.log(
              '[this.clientOrderList] ------> ',
              this.clientOrderList
            )
          } catch (error) {
            console.error('请求失败', error)

            if (error && error.err) {
              const { errorTime, msg, url } = error.msg
              const insertParams = {
                create_time: errorTime,
                url,
                message: msg
              }
              await idb.error_log.add(insertParams)
              this.errorLogList.unshift(insertParams)
              this.errorLog = createErrLogSrt(this.errorLogList)
              if (msg === '淘宝登录过期') {
                this.user.taobao.login = false
                this.user.taobao.name = '淘宝登录过期'
                this.$message.error('淘宝登录过期，请重试')
              }
            }
          }
        }
      }
    },
    // 持久化客户端
    saveClient () {
      // 如果存在就保持
      if (this.user.taobao.name && this.user.taobao.id) {
        Vue.ls.set(CLIENT_LOGIN, this.user, 7 * 24 * 60 * 60 * 1000)
        // 使用客户端持久化的信息，修改标题
        setDocumentTitle(`礼品代发-已登录淘宝店铺（${this.user.taobao.name}）`)
      }
    },
    // 输入框按下回车
    changeTime (e) {
      const { value } = e.target
      this.timing.time = value
      this.timing.flag = false
      this.control()
    },
    deleteLog () {
      this.log = ''
      clearLog()
    },
    deleteErrLog () {
      this.errorLog = ''
      clearErrLog()
    }
  }
}
</script>

<style scoped>
.tb_icon {
  margin-right: 8px;
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(~@/assets/jl.png);
  background-position: -24px -120px;
}
</style>
