import Dexie from 'dexie'

const dbInfo = {
  db_name: 'log',
  db_version: 1
}

const idb = new Dexie(dbInfo.db_name)

idb.version(dbInfo.db_version).stores({
  log: '++id, create_time, url, message',
  error_log: '++id, create_time, url, message'
})

idb.open()

export default idb
