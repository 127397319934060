import dayjs from 'dayjs'
import idb from './db'

/**
 * 获取订单的参数
 * @param {string} cookieStr cookie 字符串
 * @returns
 */
export const getListParams = cookieStr => {
  const url =
    'https://trade.taobao.com/trade/itemlist/asyncSold.htm?event_submit_do_query=1&_input_charset=utf8'
  const headers = {
    'Accept-Encoding': 'deflate, gzip',
    'Accept-Language': 'zh-CN,zh;q=0.9',
    accept: 'application/json, text/javascript, */*; q=0.01',
    authority: 'trade.taobao.com',
    'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    origin: 'https://trade.taobao.com',
    referer:
      'https://trade.taobao.com/trade/itemlist/list_sold_items.htm?spm=a2oq0.12575281.favorite.d672.16c11debpOviq7&mytmenu=ymbb',
    'sec-ch-ua-mobile': '?0',
    'sec-fetch-dest': 'empty',
    'sec-fetch-mode': 'cors',
    'sec-fetch-site': 'same-origin',
    'x-requested-with': 'XMLHttpRequest',
    cookie: cookieStr
  }
  const submitParams = {
    action: 'itemlist/SoldQueryAction',
    buyerNick: '',
    dateBegin: '',
    dateEnd: '',
    itemTitle: '',
    orderStatus: 'PAID',
    pageNum: '1',
    pageSize: '15',
    queryMore: 'true'
  }
  return { url, headers, dataParam: submitParams }
}

/**
 * 创建 log 字符串
 * @param {Array<{create_time:number,message: string}>} list
 * @returns {string} 日志字符串
 */
export const createLogStr = list => {
  const strList = list.map(item => {
    // eslint-disable-next-line
    const { create_time, message } = item
    const time = dayjs(create_time).format('YYYY-MM-DD HH:mm:ss')
    return `[${time}]: ${message}\n`
  })
  return strList.reduce((previous, current) => previous + current, '')
}
/**
 * 创建 error_log 字符串
 * @param {Array<{create_time:number,message: string}>} list
 * @returns {string} 错误日志字符串
 */
export const createErrLogSrt = list => {
  const strList = list.map(item => {
    // eslint-disable-next-line
    const { create_time, url, message } = item
    const time = dayjs(create_time).format('YYYY-MM-DD HH:mm:ss')
    const hasUrl = `[${time}]: 错误链接: [${url}], 错误信息: [${message}]\n`
    const notUrl = `[${time}]: 错误信息: [${message}]\n`
    return url ? hasUrl : notUrl
  })
  return strList.reduce((previous, current) => previous + current, '')
}

/**
 * 查询日志表 最后 50 条
 * @returns
 */
export const queryLog = async () => {
  const logList = await idb['log']
    .orderBy('create_time')
    .reverse()
    .limit(50)
    .toArray()
  const errLogList = await idb['error_log']
    .orderBy('create_time')
    .reverse()
    .limit(50)
    .toArray()
  return { log: logList, errLog: errLogList }
}

/**
 * 清空发货日志表
 * @returns {Promise<boolean>}
 */
export const clearLog = () => {
  return new Promise((resolve, reject) => {
    idb
      .transaction('rw', 'log', () => {
        idb['log'].clear()
      })
      .then(() => {
        console.info('[commit delete]')
        resolve(true)
      })
      .catch(error => {
        console.error('[delete error] ------> ', error)
        reject(error)
      })
  })
}

/**
 * 清空发货错误日志表
 * @returns {Promise<boolean>}
 */
export const clearErrLog = () => {
  return new Promise((resolve, reject) => {
    idb
      .transaction('rw', 'error_log', () => {
        idb['error_log'].clear()
      })
      .then(() => {
        console.log('[ commit ]')
        resolve(true)
      })
      .catch(error => {
        console.error('[delete error] ------> ', error)
        reject(error)
      })
  })
}
